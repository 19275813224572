import WasmController from "react-lib/frameworks/WasmController";

// APIs
import OperatingOrderCheckout from "issara-sdk/apis/OperatingOrderCheckout_apps_ORM";
import OperatingOrderConfirmAdmit from "issara-sdk/apis/OperatingOrderConfirmAdmit_apps_ORM";
import OperatingOrderConfirmServiceCompensation from "issara-sdk/apis/OperatingOrderConfirmServiceCompensation_apps_ORM";
import OperatingOrderDetail from "issara-sdk/apis/OperatingOrderDetail_apps_ORM";
import OperatingItemList from "issara-sdk/apis/OperatingItemList_apps_ORM";
import OperatingDetailDetail from "issara-sdk/apis/OperatingDetailDetail_apps_ORM";
import OperatingDetailBasicInfo from "issara-sdk/apis/OperatingDetailBasicInfo_apps_ORM";
import OperativeImageList from "issara-sdk/apis/OperativeImageList_apps_ORM";
import DoctorList from "issara-sdk/apis/DoctorList_core";

// SEQ
import * as ORQueueI from "./sequence/ORQueue";
import * as ORCountsI from "./sequence/ORCounts";
import * as PreOperationI from "./sequence/PreOperation";
import * as ORRequestI from "./sequence/ORRequest";
import * as ORPostOperationI from "./sequence/ORPostOperation";
import * as PerioperativeNursingI from "./sequence/PerioperativeNursing";
import * as ORHistoryI from "./sequence/ORHistory";
import * as ORCancelListI from "./sequence/ORCancelList";
import * as ORCaseListI from "./sequence/ORCaseList";
import * as OperatingDateTimeI from "./sequence/OperatingDateTime";
import * as ManageORI from "./sequence/ManageOR";
import * as ORServiceRefundI from "./sequence/ORServiceRefund";

import FormPostOperation from "react-lib/apps/HISV3/ORM/FormPostOperation";
import getPdfMake from "react-lib/appcon/common/pdfMake";
import { adToBe } from "react-lib/utils/dateUtils";
import moment from "moment";
import { toast } from "react-toastify";

export type State = {
  orDischargeResult?: any;
} & ORQueueI.State &
  ORCountsI.State &
  PreOperationI.State &
  ORPostOperationI.State &
  ORRequestI.State &
  PerioperativeNursingI.State &
  ORHistoryI.State &
  ORCancelListI.State &
  ORCaseListI.State &
  ManageORI.State &
  OperatingDateTimeI.State &
  ORServiceRefundI.State;

export const StateInitial: State = {
  orDischargeResult: null,
  // ...ORQueueI.StateInitial,
  // ...ORCountsI.StateInitial,
  // ...PreOperationI.StateInitial,
  // ...ORPostOperationI.StateInitial,
  // ...ORRequestI.StateInitial,
  // ...PerioperativeNursingI.StateInitial,
  // ...ORHistoryI.StateInitial,
  // ...ORCancelListI.StateInitial,
  // ...ORCaseListI.StateInitial,
  // ...OperatingDateTimeI.StateInitial,
  // ...ManageORI.StateInitial,
  // ...ORServiceRefundI.StateInitial
};

export type Event =
  // checkout
  | { message: "GetCheckoutMaster" }
  | { message: "ORCheckout"; params: any }
  // discharge
  | { message: "GetDischargeMaster" }
  | { message: "ORDischarge"; params: any }
  | { message: "ORConfirmCompensation"; params: any }
  | { message: "ORConfirmAdmit"; params: any }
  // cancel discharge
  | { message: "ORCancelDischarge"; params: any }
  // get
  | { message: "GetOROrderByID"; params: any }
  | { message: "SearchORItemList"; params: any }
  | { message: "PrintOperativeNote"; params: any }
  | ORQueueI.Event;

export type Data = {
  division?: number;
} & ORQueueI.Data &
  ORCountsI.Data &
  PreOperationI.Data &
  ORPostOperationI.Data &
  ORRequestI.Data &
  PerioperativeNursingI.Data &
  ORHistoryI.Data &
  ORCancelListI.Data &
  ORCaseListI.Data &
  OperatingDateTimeI.Data &
  ManageORI.Data &
  ORServiceRefundI.Data;

export const DataInitial = {
  ...ORQueueI.DataInitial,
  ...ORCountsI.DataInitial,
  ...PreOperationI.DataInitial,
  ...ORPostOperationI.DataInitial,
  ...ORRequestI.DataInitial,
  ...PerioperativeNursingI.DataInitial,
  ...ORHistoryI.DataInitial,
  ...ORCancelListI.DataInitial,
  ...ORCaseListI.DataInitial,
  ...ManageORI.DataInitial,
  ...OperatingDateTimeI.DataInitial,
};

type Handler = (controller: WasmController<State, Event, Data>, params?: any) => any;

export const DidMount: Handler = async (controller, params) => {};

// OR Checkout
export const GetCheckoutMaster: Handler = async (controller) => {
  controller.handleEvent({
    message: "GetMasterData" as any,
    params: {
      masters: [["planDischargeType", {}]],
    },
  });
};

export const ORCheckout: Handler = async (controller, params) => {
  const [response, error, network] = await OperatingOrderCheckout.update({
    apiToken: controller.apiToken,
    pk: params.order?.id,
    data: {
      checkout_cause: params.checkout_cause,
      death_datetime: params.death_datetime,
    },
  });
  if (!error) {
    GetOROrderByID(controller, params);
    controller.handleEvent({
      message: "HandleRefreshEncounter" as any,
      params: {},
    });
  }
};

// OR Discharge
export const GetDischargeMaster: Handler = async (controller) => {
  controller.handleEvent({
    message: "GetMasterData" as any,
    params: {
      masters: [
        ["patientCondition", {}],
        ["patientDischarge", {}],
        ["orTransferLocation", {}],
      ],
    },
  });
};

export const ORDischarge: Handler = async (controller, params) => {
  const state = controller.getState();

  const [response, error, network] = await OperatingOrderDetail.update({
    apiToken: controller.apiToken,
    pk: params.order?.id,
    data: {
      action: "COMPLETE",
      result: params.result,
      admit_detail: params.admit_detail,
      admit_result: params.admit_result,
      predischarge_condition: params.predischarge_condition,
      discharge_status: params.discharge_status,
      discharge_note: params.discharge_note,
      transfer_location: params.transfer_location,
      patient_out_datetime: params.patient_out_datetime,
    },
    extra: {
      division: controller.data.division,
    },
  });
  if (!error) {
    controller.setState({ orDischargeResult: response });
  } else {
    controller.setState({
      errorMessage: { ...state.errorMessage, [params.card]: error },
    });
  }
};

export const ORConfirmCompensation: Handler = async (controller, params) => {
  const [response, error, network] = await OperatingOrderConfirmServiceCompensation.put({
    apiToken: controller.apiToken,
    pk: params.order?.id,
  });
};

export const ORConfirmAdmit: Handler = async (controller, params) => {
  const [response, error, network] = await OperatingOrderConfirmAdmit.put({
    apiToken: controller.apiToken,
    pk: params.order?.id,
  });
};

// OR Cancel Discharge
export const ORCancelDischarge: Handler = async (controller, params) => {
  const [response, error, network] = await OperatingOrderDetail.update({
    apiToken: controller.apiToken,
    pk: params.order?.id,
    data: {
      action: "CANCEL_COMPLETE",
      cancel_reason: params.cancel_reason,
    },
  });
  if (!error) {
    GetOROrderByID(controller, params);
  }
};

// GET
export const GetOROrderByID: Handler = async (controller, params) => {
  const [response, error, network] = await OperatingOrderDetail.retrieve({
    apiToken: controller.apiToken,
    pk: params.order?.id,
  });
  if (!error) {
    controller.setState({ selectedOrOrder: response });
  }
};

export const SearchORItemList: Handler = async (controller, params) => {
  const [response, error, network] = await OperatingItemList.list({
    params: { search: params?.searchText, limit: 100 },
    apiToken: controller.apiToken,
  });
  if (!error) {
    controller.setState({ searchOrItems: response?.items });
  }
};

export const PrintOperativeNote: Handler = async (controller, params) => {
  const state = controller.getState();
  console.log("test_patientPanelData", params.patientPanelData?.patientData);
  let selectedPatient = state.selectedPatient || params.patientPanelData?.patientData;

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [params.buttonLoadKey]: "LOADING",
    },
  });

  const [[operatingRes, operatingErr, operatingNet], [basicInfoRes, basicInfoErr, basicInfoNet]] =
    await Promise.all([
      OperatingDetailDetail.retrieve({
        apiToken: controller.apiToken,
        pk: state.selectedOrOrder?.operating_detail,
        extra: {
          device: controller.data.device,
          division: controller.data.division,
        },
      }),
      OperatingDetailBasicInfo.retrieve({
        apiToken: controller.apiToken,
        pk: state.selectedOrOrder?.operating_detail,
        extra: {
          device: controller.data.device,
          division: controller.data.division,
        },
      }),
    ]);

  if (operatingRes) {
    const surgeryTeams: Record<string, any>[] = operatingRes?.surgery_teams || [];
    const mainTeam = surgeryTeams.find((team) => team.is_main);

    if (!mainTeam?.operative_note_id) {
      toast.error("ไม่มีการบันทึกข้อมูล Operative Note");

      return;
    }

    const [operativeImageRes, operativeImageErr, operativeImageNet] = await OperativeImageList.list(
      {
        operative_note: mainTeam?.operative_note_id,
        apiToken: controller.apiToken,
        extra: {
          device: controller.data.device,
          division: controller.data.division,
        },
      }
    );

    const getDiagnosis = (data: any) => {
      if (data.lenght === 0) {
        return "-";
      } else {
        let diagnosisData = data.map((items: any) => {
          if (items?.icd_code !== null || items?.icd9cm_code !== null) {
            const code = items?.icd_code || items?.icd9cm_code;
            const term = items?.icd_term || items?.icd9cm_term;
            return `${code ? `[${code}]` : ""} ${term || ""}`;
          } else {
            return "-";
          }
        });
        return diagnosisData.join(", ");
      }
    };

    const getNameOnly = (name: any) => {
      return (name || "")?.split(" (")?.[0] || "-";
    };

    let doctorName = (mainTeam?.chief_surgeon || "")?.split("] ")?.[1];
    const [doctorRes, doctorErr, doctorNet] = await DoctorList.list({
      apiToken: controller.apiToken,
      params: { name_code: doctorName },
    });

    let birthdate = selectedPatient?.birthdate || "-";
    let gender = selectedPatient?.gender_name;
    let typeOfWound = state.ORPostOperationSequence?.type_wound?.items?.find(
      (item: any) => item.value === mainTeam?.type_of_wound
    )?.label;
    let specimen = `${mainTeam?.specimen?.isCyto && `Cyto ${mainTeam?.specimen?.cyto || 0} ขวด`} ${
      mainTeam?.specimen?.isPatho && `Patho ${mainTeam?.specimen?.patho || 0} ชิ้น`
    }`;
    let printDate = moment();

    let data = {
      hn: selectedPatient?.hn || "-",
      patientName: selectedPatient?.full_name || "-",
      birthdate: birthdate || "-",
      citizen: selectedPatient?.citizen_no || selectedPatient?.citizen_passport || "-",
      gender: gender || "-",
      age: selectedPatient?.full_age || "-",
      tel: selectedPatient?.present_address?.tel_mobile || "-",
      number: state.selectedEncounter?.number || "-",
      incisionDate: basicInfoRes?.incision_date || "-",
      incisionDatetime: `${basicInfoRes?.incision_date || "-"} [${
        basicInfoRes?.incision_time || "-"
      }]`,
      closureDatetime: `${basicInfoRes?.closure_date || "-"} [${
        basicInfoRes?.closure_time || "-"
      }]`,
      primarySurgeon: doctorRes?.items?.[0]?.full_name || "-",
      assistantSurgeons: getNameOnly(mainTeam?.assistant_surgeons_summary) || "-",
      scrubNurse: getNameOnly(mainTeam?.scrub_nurse_summary) || "-",
      circulatingNurse: getNameOnly(mainTeam?.circulating_nurse_summary) || "-",
      anesthesiologist: getNameOnly(mainTeam?.anesthesiologist_summary) || "-",
      anesthetist: getNameOnly(mainTeam?.anesthetist_summary) || "-",
      anesthesiaType: basicInfoRes?.anesthesia || "-",
      principalDiagnosis: getDiagnosis(mainTeam?.pre_principal_diagnosis),
      postOperativePrincipal: getDiagnosis(mainTeam?.post_principal_diagnosis),
      preDiagnosisDetail: mainTeam?.detail?.post_op_pre_diagnosis_remark || "",
      postDiagnosisDetail: mainTeam?.detail?.post_diagnosis_remark || "",
      postOperativeSecondary: getDiagnosis(mainTeam?.post_secondary_diagnosis),
      operation: mainTeam?.post_operating_order_item?.other_treatment || "-",
      operationPrincipal: getDiagnosis(
        (mainTeam?.post_operating_order_item?.procedures || []).filter(
          (item: any) => item.type === "PRIMARY"
        )
      ),
      operationSecondary: getDiagnosis(
        (mainTeam?.post_operating_order_item?.procedures || []).filter(
          (item: any) => item.type === "SECONDARY"
        )
      ),
      implant: mainTeam?.implant?.post?.checked ? mainTeam?.implant?.post?.value || "-" : "N/A",
      specificConcern: mainTeam?.specific_concern?.checked
        ? mainTeam?.specific_concern?.value || "-"
        : "N/A",
      typeOfWound: typeOfWound || "-",
      specimen: mainTeam?.specimen?.checked ? specimen : "N/A",
      bloodLoss: mainTeam?.blood_loss || "-",
      tourniquet: mainTeam?.tourniquet?.checked ? mainTeam?.tourniquet?.value || "-" : "N/A",
      operativeFindings: mainTeam?.detail?.operative_findings || "-",
      procedure: mainTeam?.operative_note || "-",
      complication: mainTeam?.detail?.operative_complication || "-",
      uploadImg: operativeImageRes?.items || new Array(3),
      userName: state.django.user.full_name || "-",
      printDate: printDate
        ? `${adToBe(printDate.format("DD/MM/YYYY"))} [${printDate.format("HH:mm")}]`
        : "-",
    };

    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [params.buttonLoadKey]: "SUCCESS",
      },
    });

    let docDef = await FormPostOperation({ ...data });
    return (await getPdfMake(true)).createPdf(docDef).open();
  } else {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [params.buttonLoadKey]: "ERROR",
      },
    });
  }
};
