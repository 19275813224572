import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Button
} from 'semantic-ui-react'
import {
  Table
} from 'react-lib/frameworks/Table'

const ORCountTable = (props: any) => {
    return(
      <div
        id="ORCountTable-div-0"
        style={{margin: "5px"}}>
        <div
          id="ORCountTable-div-1"
          style={{display: "flex", alignItems: "center", margin: "10px 0px"}}>
          
          <label
            id="ORCountTable-label-2"
            style={{fontWeight: "bold", fontSize: "16px", marginLeft: "14px"}}>
            {props.title}
          </label>
          <div
            id="ORCountTable-div-3"
            style={{display:"flex", width:"auto", marginLeft: "auto"}}>
            
            <Button
              color="yellow"
              id="ORCountTable-Button-4"
              onClick={props.addItem}
              size="small">
              Add
            </Button>
          </div>
        </div>
        <Table
          className="--rt-tbody-overflow-y-hidden"
          data={props.item?.counts_details}
          headers="Item,Previous,Add,Total,Scrub,Circulating,Total,"
          id="ORCountTable-Table-5"
          keys="item.nameButton,total_previous_shift,addInput,total_all_shift,sterileCountInput,nonSterileCountInput,countsInShift, del"
          minRows={4}
          showPagination={false}
          style={{maxHeight: "15vh"}}
          widths="^80, 100, ^80, 100, ^80, ^80, 100, 50">
        </Table>
      </div>
    )
}


export default ORCountTable

export const screenPropsDefault = {}

/* Date Time : Mon Mar 10 2025 11:07:57 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "id": {
          "type": "value",
          "value": "ORCountTable-div-0"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"5px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "ORCountTable-div-1"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", margin: \"10px 0px\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "label",
      "parent": 1,
      "props": {
        "children": {
          "type": "code",
          "value": "props.title"
        },
        "id": {
          "type": "value",
          "value": "ORCountTable-label-2"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", fontSize: \"16px\", marginLeft: \"14px\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "ORCountTable-div-3"
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\", width:\"auto\", marginLeft: \"auto\"}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 4,
      "name": "Button",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": "Add"
        },
        "color": {
          "type": "value",
          "value": "yellow"
        },
        "id": {
          "type": "value",
          "value": "ORCountTable-Button-4"
        },
        "onClick": {
          "type": "code",
          "value": "props.addItem"
        },
        "size": {
          "type": "value",
          "value": "small"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 5,
      "name": "Table",
      "parent": 0,
      "props": {
        "className": {
          "type": "value",
          "value": "--rt-tbody-overflow-y-hidden"
        },
        "data": {
          "type": "code",
          "value": "props.item?.counts_details"
        },
        "headers": {
          "type": "value",
          "value": "Item,Previous,Add,Total,Scrub,Circulating,Total,"
        },
        "id": {
          "type": "value",
          "value": "ORCountTable-Table-5"
        },
        "keys": {
          "type": "value",
          "value": "item.nameButton,total_previous_shift,addInput,total_all_shift,sterileCountInput,nonSterileCountInput,countsInShift, del"
        },
        "minRows": {
          "type": "code",
          "value": "4"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{maxHeight: \"15vh\"}"
        },
        "widths": {
          "type": "value",
          "value": "^80, 100, ^80, 100, ^80, ^80, 100, 50"
        }
      },
      "seq": 5,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "ORCountTable",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
